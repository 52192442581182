export const templates_a = [
    { headerName: "نام کالا", field: "bArzehRadifNamadKala" },
    { headerName: "تالار", field: "Talar" },
    { headerName: "تولید کننده", field: "xTolidKonandehSharh" },
    { headerName: "عرضه کننده", field: "ArzehKonandeh" },
    { headerName: "حجم کالای قابل عرضه", field: "bArzehRadifArzeh" },
    { headerName: "قیمت پایه", field: "bArzehRadifMab" },
    { headerName: "مکان تحویل", field: "xMahalTahvilSharh" },
    { headerName: "نوع بسته بندی", field: "bArzehRadifShekl" },
    { headerName: " تاریخ تحویل", field: "bArzehRadifTarTahvil" },
    { headerName: " کد عرضه", field: "bArzehRadifPK" },
    { headerName: " نوع تسویه", field: "bArzehRadifTasviehTypeSharh" },
];


export const templates_s_a = [
    { headerName: "نام کالا", field: "bArzehRadifNamadKala" },
    { headerName: "تالار", field: "Talar" },
    { headerName: "تولید کننده", field: "xTolidKonandehSharh" },
    { headerName: "عرضه کننده", field: "ArzehKonandeh" },
    { headerName: "حجم کالای قابل عرضه", field: "bArzehRadifArzeh" },
    { headerName: "قیمت پایه", field: "bArzehRadifMab" },
    { headerName: "مکان تحویل", field: "xMahalTahvilSharh" },
    { headerName: "نوع بسته بندی", field: "bArzehRadifShekl" },
    { headerName: " تاریخ تحویل", field: "bArzehRadifTarTahvil" },
    { headerName: " کد عرضه", field: "bArzehRadifPK" },
];


export const amarFiziki = [
    { field: "GoodsName", headerName: "نام کالا" },
    { field: "Price1", headerName: "قیمت پایانی میانگین موزون " },
    { field: "TotalPrice", headerName: "ارزش معامله " },
    { field: "MinPrice", headerName: "پایین ترین" },
    { field: "MaxPrice", headerName: "بالاترین" },
    { field: "ArzeBasePrice", headerName: "قیمت پایه عرضه" },
    { field: "taghaza", headerName: "تقاضا" },
    { field: "arze", headerName: "حجم عرضه" },
    { field: "date", headerName: "تاریخ معامله" },
    { field: "DeliveryDate", headerName: "تاریخ تحویل" },
    { field: "ArzehKonandeh", headerName: "عرضه کننده" },
    { field: "PacketName", headerName: "نوع بسته بندی" },
];

export const amarFari = [
    { field: "GoodsName", headerName: "نام کالا" },
    { field: "ProducerName", headerName: "تولید کننده" },
    { field: "ArzeBasePrice", headerName: "قیمت پایه عرضه" },
    { ield: "arze", headerName: "حجم عرضه" },
    { field: "taghaza", headerName: "تقاضا" },
    { field: "Quantity", headerName: "حجم قرارداد" },
    { field: "date", headerName: "تاریخ معامله" },
    { field: "Warehouse", headerName: "مکان تحویل" },
    { field: "PacketName", headerName: "نوع بسته بندی" },
    { field: "Tasvieh", headerName: "نوع تسویه" },
];

export const amarSaderati = [
    { field: "GoodsName", headerName: "نام کالا" },
    { field: "ProducerName", headerName: "تولید کننده" },
    { field: "TotalPrice", headerName: "ارزش معامله " },
    { field: "MinPrice", headerName: "پایین ترین" },
    { field: "MaxPrice", headerName: "بالاترین" },
    { field: "ArzeBasePrice", headerName: "قیمت پایه عرضه" },
    { field: "arze", headerName: "حجم عرضه" },
    { field: "taghaza", headerName: "تقاضا" },
    { field: "Quantity", headerName: "حجم قرارداد" },
    { field: "date", headerName: "تاریخ معامله" },
    { field: "DeliveryDate", headerName: "تاریخ تحویل" },
    { field: "Warehouse", headerName: "مکان تحویل" },
    { field: "Price1", headerName: "قیمت پایانی میانگین موزون " },

];


export const printParams = {
    PDF_HEADER_COLOR: "#f6f6f6",
    PDF_INNER_BORDER_COLOR: "#d1d5db",
    PDF_OUTER_BORDER_COLOR: "#fecaca",
    PDF_LOGO: "https://panel.charismabroker.com/images/logo1.png",
    PDF_PAGE_ORITENTATION: "landscape",
    PDF_WITH_HEADER_IMAGE: true,
    PDF_WITH_FOOTER_PAGE_COUNT: true,
    PDF_HEADER_HEIGHT: 25,
    PDF_ROW_HEIGHT: 15,
    PDF_ODD_BKG_COLOR: "#fcfcfc",
    PDF_EVEN_BKG_COLOR: "#fff",
    PDF_WITH_CELL_FORMATTING: false,
    PDF_WITH_COLUMNS_AS_LINKS: true,
    PDF_SELECTED_ROWS_ONLY: false,
};
