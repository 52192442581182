export function convertDate(string) {
  return string.replace(
    /[\u0660-\u0669\u06f0-\u06f9]/g,
    (c) => c.charCodeAt(0) & 0xf
  );
}
export default function getDocDefinition(
  printParams,
  agGridApi,
  agGridColumnApi,
  template_type,
  title,
  start
) {
  const {
    PDF_HEADER_COLOR,
    PDF_INNER_BORDER_COLOR,
    PDF_OUTER_BORDER_COLOR,
    PDF_ODD_BKG_COLOR,
    PDF_EVEN_BKG_COLOR,
    PDF_HEADER_HEIGHT,
    PDF_ROW_HEIGHT,
    PDF_PAGE_ORITENTATION,
    PDF_WITH_CELL_FORMATTING,
    PDF_WITH_COLUMNS_AS_LINKS,
    PDF_SELECTED_ROWS_ONLY,
    PDF_WITH_HEADER_IMAGE,
    PDF_WITH_FOOTER_PAGE_COUNT,
    PDF_LOGO,
  } = printParams;

  return (function () {
    // const columnGroupsToExport = getColumnGroupsToExport();

    const columnsToExport = getColumnsToExport();

    const widths = getExportedColumnsWidths(columnsToExport);

    const rowsToExport = getRowsToExport(columnsToExport);

    const body = [columnsToExport, ...rowsToExport];

    const headerRows =  1;

    // heade
    const header = {
      color: "#000",
      
      columns: [
        {
          image: "ag-grid-logo",
          width: 120,
          alignment: "center",
          margin: [70,-10,0,60],
        },
        { text: "" },
        {
          text: `${convertDate(start)}  ${myFunction(title)}`,
          margin: [0, 35, 0, 10],
          fontSize: 13,

          
          // alignment: "center",
        },
      ],
    };
    // footer
    const footer = PDF_WITH_FOOTER_PAGE_COUNT
      ? function (currentPage, pageCount) {
          return {
            columns: [
              {
                text:
                  pageCount +
                  " " +
                  " از " +
                  "  " +
                  currentPage.toString() +
                  " " +
                  " صفحه ",
              },
              // {text:'1402/06/21'}
            ],
            margin: [20],
          };
        }
      : null;

    const pageMargins = [
      10,
      PDF_WITH_HEADER_IMAGE ? 80 : 20,
      10,
      PDF_WITH_FOOTER_PAGE_COUNT ? 20 : 10,
    ];

    const heights = (rowIndex) =>
      rowIndex < headerRows ? PDF_HEADER_HEIGHT : PDF_ROW_HEIGHT;

    const fillColor = (rowIndex, node, columnIndex) => {
      if (rowIndex < node.table.headerRows) {
        return "#2654d3";
      }
      return rowIndex % 2 === 0 ? "#efefef" : "#fff";
    };

    const hLineWidth = (i, node) =>
     i === 0 ||  i === node.table.body.length ? 1 : 1;

    const vLineWidth = (i, node) =>
     i === 0 ||  i === node.table.widths.length ? 1 : 1;

    const hLineColor = (i, node) =>
     i === 0 ||  i === node.table.body.length ? "#ccc" : PDF_INNER_BORDER_COLOR;

    const vLineColor = (i, node) =>
     i === 0 ||  i === node.table.widths.length
        ? "#ccc"
        : PDF_INNER_BORDER_COLOR;

    const docDefintiion = {
      pageOrientation: PDF_PAGE_ORITENTATION,
      header,
      footer,
      info: {
        title: `${convertDate(start)}  ${myFunction(title)}`,
        author: "Rohan",
      },

      pageSize: "A3",
      // pageSize: "B2",
      watermark: {
        text: "کاریزما",
        color: "#999",
        opacity: 0.1,
        // bold: true,
        italics: false,
      },
      content: [
        {
          style: "myTable",
          table: {
            headerRows,
            widths,
            body,
            heights,
          },

          layout: {
            fillColor,
            hLineWidth,
            vLineWidth,
            hLineColor,
            vLineColor,
          },
        },
      ],
      images: {
        "ag-grid-logo": PDF_LOGO,
      },
      styles: {
        myTable: {
          margin: [0, 8, 0, 8],
          fontSize: 10,
        },
        tableHeader: {
          bold: true,
          margin: [8, PDF_HEADER_HEIGHT / 4, 8, 8],
          fontSize: 10,
          noWrap: true,
          direction: "rtl",
          rtl: true,
          color: "white",

        },
        tableCell: {
          fontSize: 9.5,
          // bold: false,
          margin: [0, 8, 0, 8],
          direction: "rtl",
          rtl: true,
          noWrap: false,

          // noWrap: parseInt(template_type)===2?true:false
        },
      },
      defaultStyle: {
        font: "sans",
        alignment: "center",
        fontSize: 10,
      },
      pageMargins,
    };

    return docDefintiion;
  })();



  function getColumnsToExport() {
    let columnsToExport = [];
    // const columns = gridApi.getAllDisplayedColumns();
    agGridApi?.getAllDisplayedColumns().reverse().forEach((col) => {
      let pdfExportOptions = getPdfExportOptions(col.getColId());
      if (pdfExportOptions && pdfExportOptions.skipColumn) {
        return;
      }
      let headerCell = createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    return columnsToExport;
  }

  function getRowsToExport(columnsToExport) {
    let rowsToExport = [];

    agGridApi.forEachNodeAfterFilterAndSort((node) => {
      if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
        return;
      }
      let rowToExport = columnsToExport.map(({ colId }) => {
        let cellValue = agGridApi.getValue(colId, node);
        // if (colId === "bArzehRadifArzehSharh") {
        //   cellValue=cellValue.toString().replace(/\s+/," ");
        //   // cellValue=cellValue.toString().replace(/\/([آ-ی]+)/,"\*$1");

        //   // cellValue=cellValue.replace(/([آ-ی]+\/)/,"$1\n");
        //   // cellValue=cellValue.replace(/([آ-ی]+\.?)/,"$1\n");
        //   cellValue=myFunction(cellValue)

        // }
        // if (colId !== "bArzehRadifArzehSharh") {
       if (cellValue) {
          cellValue = myFunction(cellValue);
          cellValue = cellValue.replace(/\(([آ-ی]+)\)/, ")$1(");
        }

        // }

        return createTableCell(cellValue, colId);
        // return tableCell;
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }

  function getExportedColumnsWidths(columnsToExport) {

    return  `${100 / columnsToExport.length}%`
    // if (parseInt(template_type) === 1 || parseInt(template_type) ===2){
    //   return "*";
    // }
    // if (parseInt(template_type) ===3) {
    //  return '*'
    // }
    // columnsToExport.map(() => 100 / columnsToExport.length + "%");
    // if (parseInt(template_type) <= 2) {
    //   return "*";
    // }
    // if (parseInt(template_type) > 2) {
    //   return "auto";
    // }
    // columnsToExport.map(() => 100 / columnsToExport.length + "%");
    // return;
    // }

    // return "auto";
  }

  function createHeaderCell(col) {
    let headerCell = {};

    let isColGroup = col.hasOwnProperty("children");

    if (isColGroup) {
      headerCell.text = col.originalColumnGroup.colGroupDef.headerName;
      headerCell.colSpan = col.children.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = myFunction(headerName);
      // headerCell.text =headerName.replace(/\(([آ-ی]+)\)/,")$1(")
      headerCell.colId = col.getColId();
    }

    headerCell["style"] = "tableHeader";

    return headerCell;
  }

  function createTableCell(cellValue, colId) {
    const tableCell = {
      text: cellValue !== undefined ? cellValue : "",
      style: "tableCell",
    };

    const pdfExportOptions = getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL } = pdfExportOptions;

      if (PDF_WITH_CELL_FORMATTING && styles) {
        Object.entries(styles).forEach(
          ([key, value]) => (tableCell[key] = value)
        );
      }

      if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
        tableCell["link"] = createURL(cellValue);
        tableCell["color"] = "blue";
        tableCell["decoration"] = "underline";
      }
    }

    return tableCell;
  }

  function getPdfExportOptions(colId) {
    let col = agGridColumnApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }

  function myFunction(string) {
    if (string) {
      let str1WordArr = string.toString().split(" ");
      let reverseWord = [];
      for (let i = str1WordArr.length - 1; i >= 0; i--) {
        reverseWord.push(str1WordArr[i]);
      }
      return reverseWord.join("  ");
    }

    return string;
  }

  // function reverseWords(string) {
  //   if (string !== null) {
  //     let str1WordArr = string.toString().split("");
  //     let reverseWord = [];
  //     for (let i = str1WordArr.length - 1; i >= 0; i--) {
  //       reverseWord.push(str1WordArr[i]);
  //     }
  //     return reverseWord.join("");
  //   }

  //   return string;
  // }

  // function reverseSentence(sentence) {
  //   var words = sentence.split(" ");
  //   var reversedWords = words.reverse();
  //   var reversedSentence = reversedWords.join(" ");
  //   return reversedSentence;
  // }

  //   function reverseParentheses(sentence) {
  //     var reversedSentence = sentence.replace(/\(|\)/g, function(match) {
  //         if (match === "(") {
  //             return ")";
  //         } else if (match === ")") {
  //             return "(";
  //         }
  //     });

  //     return reversedSentence;
  // }
}
