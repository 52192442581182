
export const install = (app) => {

    const emitter=app.config.globalProperties.emitter
    app.axios.interceptors.request.use(function (config){
        return config
    })
    app.axios.interceptors.response.use(function (response, config) {
        return response;
    },function (error){

        if(error.response!==undefined && error.response.status===401){
            console.log('401')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            location.assign('/login')
            return ;
        }
        return Promise.reject(error)
    })
}