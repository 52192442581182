<script setup>
import {inject, ref} from "vue";

const emitter=inject('emitter')
const show=ref(false)
const message=ref('')
const color=ref('green')
emitter.on('showSnackSuccess',(value)=>{
  show.value=true
  message.value=value
})
emitter.on('showSnackError',(value)=>{
  show.value=true
  message.value=value
  color.value='red'
})
</script>

<template>
<v-snackbar :color="color" timeout="10000" v-model="show">
  <p class="text-center">{{message}}</p>
</v-snackbar>
</template>

