import axios from "axios";
const BASE_URL = "https://admin.charismabroker.com/api/get";
// const BASE_URL = "http://127.0.0.1:8000/api/get";
// const BASE_URL = "https://www.ime.co.ir/subsystems/ime/auction/auction.ashx";

const buildUrl = (start, end, offset, type,f) =>
    `${BASE_URL}/${type}?start=${start}&end=${end}&offset=${offset}&field=${f}`;
const fetchItems = async (urls) => {
    try {
        const responses = await Promise.all(urls.map((url) => axios.get(url)));
        return responses.flatMap((response) => response.data.rows);
    } catch (error) {
        return error;
    }
};
function convertDate(string) {
    return string.replace(
        /[\u0660-\u0669\u06f0-\u06f9]/g,
        (c) => c.charCodeAt(0) & 0xf
    );
}

export async function UseFetchData(start, end, type ) {
    var t = type ;
    var f='bArzehTarArzeh'
    switch (type) {
        case 1:
            t='ArzeFiziki'
            f='bArzehTarArzeh'
            break;
        case 2:
            t='ArzeFarei'
            f='bArzehTarArzeh'
            break;
        case 3:
            t='ArzeSaderati'
            f='bArzehTarArzeh'
            break;
        case 4:
            t='AmarFiziki'
            f='date'
            break;
        case 5:
            t='AmarFarei'
            f='date'
            break;
        case 6:
            t='AmarSaderati'
            f='date'
            break;

        default:
            break;
    }


    try {
        const start_date = convertDate(start.toString());
        const end_date = convertDate(end.toString());
        const { data } = await axios.get(buildUrl(start_date, end_date, 0, t,f));
        let page = 0;
        const urls = [];
        while (page <= Math.floor(data.total / 300)) {
            var url = `${BASE_URL}/${t}?start=${start_date}&end=${end_date}&field=${f}&offset=${
                300 * page
            }`;
            urls.push(url);
            page++;
        }
        const items = await fetchItems(urls);
        return { items, success: true };
    } catch (error) {
        if(error?.code==="ERR_BAD_REQUEST"){
            return {items:[],success:false,code:422};
        }

    }
}
