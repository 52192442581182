<script setup>
import {ref} from "vue";

const list_energy=ref([
  {name:'بازار فیزیکی',id:1},
  {name:'بازار مشفقه',id:2},
  {name:'بازار سایر اوراق',id:3},
  {name:'بازار برق',id:4},
])
</script>

<template>
  <div class="grid grid-cols-2 gap-6 pt-4">
    <div  class="flex-col-center bg-[#212733] gap-6 p-3 border-b-4 border-blue-500 rounded-xl" v-for="item in list_energy">
      <img class="w-16 h-16 object-cover invert" :src="`/energy/icon${item.id}.png`" alt=""/>
      <p>{{item.name}}</p>
    </div>
  </div>
</template>

<style scoped>

</style>