import {ref} from "vue";

export function useColor(){
   return  ref([
        {name: 'عرضه فیزیکی', color: 'amberr', id: 1, count: 32},
        {name: 'عرضه فرعی', color: 'rose', id: 2, count: 32},
        {name: 'عرضه صادارتی', color: 'emerald', id: 3, count: 32},
        {name: 'آمار فیزیکی', color: 'indigoo', id: 4, count: 32},
        {name: 'آمار فرعی', color: 'fuchsia', id: 5, count: 32},
        {name: 'آمار صادراتی', color: 'sky', id: 6, count: 32},
    ])


}