import {createApp} from 'vue'
import App from './App.vue'
import './assets/main.css'
import './registerServiceWorker'
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
import {useServices} from "@/boot/index.js";
import {install} from "@/service/axiosService.js";
import router from "@/router";

const app = createApp(App)
app.component('DatePicker', Vue3PersianDatetimePicker)

useServices(app)
app.use(install)
app.use(router)
app.mount('#app')
